import { useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getReq } from "../../Services/Query/Query";
import { LinearProgress } from "@mui/material";
import React from "react";
import DataPage from "./DataPage";
import Liveness from "./Liveness";
import FaceMatch from "./FaceMatch";
import queryString from "query-string";

export default function Profile() {
  const navigate = useNavigate();
  const { search, pathname } = useLocation();
  const { path, zin } = queryString.parse(search);

  const { data, isLoading } = useQuery({
    queryKey: ["get-all-users", { route: `vendors/user/${zin}`, params: {} }],
    queryFn: getReq,
  });

  const SCREEN_MAPPING = {
    "data-page": <DataPage {...{ data }} />,
    liveness: <Liveness {...{ data }} />,
    "face-match": <FaceMatch {...{ data }} />,
  };

  const handleNavigate = (route) => {
    const params = new URLSearchParams(search);
    params.delete("path");
    params.append("path", route);
    navigate(`${pathname}?${params}`);
  };

  if (isLoading) return <LinearProgress />;

  return (
    <div>
      <div className="px-7 flex items-center gap-7 border-b border-b-gray-100 bg-white">
        {[
          { label: "Data Page", route: "data-page" },
          { label: "Liveness", route: "liveness" },
          { label: "Face Match", route: "face-match" },
        ].map((item, index) => (
          <div key={index}>
            <button
              onClick={() => handleNavigate(item.route)}
              className={`py-3 text-gray-500  ${
                path === item.route ? " font-bold text-black  border-b-[2px] border-violet-500" : ""
              }`}
            >
              {item.label}
            </button>
          </div>
        ))}
      </div>
      <div>{SCREEN_MAPPING[path] || <DataPage />}</div>
    </div>
  );
}
