import React, { useState } from 'react';
import { Modal, Button } from 'antd';
import Propic from '../../Assets/images/blackAvatar.png';
import avat from '../../Assets/images/avatarfull.png';
import ID from '../../Assets/images/ID.png';
import Backview from '../../Assets/images/backview.png';
import Backviewdummy from '../../Assets/images/backviewdummy.jpg';
import { MdCircle } from "react-icons/md";

export default function Livness() {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');

    const showModal = (image) => {
        setSelectedImage(image);
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const userInfo = {
        name: 'Tolani Daniel Olorunyomi',
        profilePic: Propic,
        credentials: [
            { id: '1', credential: ID, backview: Backview, type: 'National Identity Number' },
            { id: '2', credential: ID, backview: Backviewdummy, type: 'National Identity Number' },
            { id: '3', credential: ID, backview: Backview, type: 'National Identity Number' }
        ]
    };

    const Data = [
        {
            position:'RIGHT',
            image:Propic,
            quality:'55%',
            time:'06/08/2024, 11:44am'
        },
        {
            position:'LEFT',
            image:Propic,
            quality:'55%',
            time:'06/08/2024, 11:44am'
        },
        {
            position:'FACE UP',
            image:Propic,
            quality:'55%',
            time:'06/08/2024, 11:44am'
        },
        {
            position:'FACE DOWN',
            image:Propic,
            quality:'55%',
            time:'06/08/2024, 11:44am'
        },
    ];

    const Duplicates = [
        {
            position:'RIGHT',
            image:avat,
            quality:'55%',
            time:'06/08/2024, 11:44am'
        }
    ];

    return (
        <div className='bg-gray-100'>
            <div className='px-5 py-3 border-b-[2px] border-b-gray-300 bg-white md:flex items-center justify-between gap-5'>
                <div className='md:flex items-center gap-3'>
                    <div className='flex items-center gap-5'>
                        <img className='rounded-full w-[50px] h-[50px]' src={userInfo.profilePic} alt="Profile" />
                        <div className='w-full font-bold'>{userInfo.name}</div>
                    </div>
                    <div className='flex md:justify-start justify-center items-center gap-10'>
                        <small className='flex items-center gap-2'><span className='text-green-700 font-bold'>Verified</span> <span className='font-bold text-gray-500'>5mins ago</span></small>
                        <small className='flex items-center gap-5'><span>Fraud Score <span className='text-violet-800 font-bold text-lg'>65%</span></span></small>
                    </div>
                </div>
                
                <div className='flex md:justify-start justify-center items-center gap-3'>
                    <span>Status:</span>
                    <small className='flex items-center gap-2 py-1 px-2 text-blue-600 bg-blue-100 rounded-[15px]'>
                        <MdCircle size={10} />
                        Active
                    </small>
                </div>
            </div>
            <div className='px-5 pb-5'>
                <section className='py-3'>
                    <div className='flex items-center gap-2'>
                        <span className='font-bold'>Liveness </span>
                        <span className='text-blue-600'>06/08/2024</span>
                    </div>
                    <div className='py-3 flex items-center flex-wrap gap-4'>
                    { Data.map((item,index)=>
                        <div key={index} className='p-3 md:w-[30%] w-full bg-white rounded-md'>
                            <div className='flex'>
                                <div className='md:w-[135px] w-[70%]'>
                                    <img className='md:w-[135px] w-full' src={item.image} alt="" />
                                </div>
                                <div className='w-[30%] h-full flex flex-col gap-5'>
                                    <small className='text-blue-900 font-bold'>{item.position}</small>
                                    <div className='flex flex-col gap-1'>
                                        <small>Quality</small>
                                        <small className='text-green-700 font-cereal_medium'>{item.quality}</small>
                                    </div>
                                </div>
                            </div>
                            <div className='flex items-center gap-1 mt-3'>
                                <small>Uploaded:</small>
                                <small className='font-bold '>{item.time}</small>
                            </div>
                        </div>
                    )}
                    </div>
                </section>
                <section className='py-3'>
                    <div className='flex items-center gap-2'>
                        <span className='font-bold'>Duplicates </span>
                        <span className='text-blue-600'>06/08/2024</span>
                    </div>
                    <div className='py-3 flex items-center flex-wrap gap-4'>
                    { Duplicates.map((item,index)=>
                        <div key={index} className='p-3 bg-white rounded-md'>
                            <div className='flex'>
                                <div className='relative'>
                                    <img className='w-[135px] h-full object-cover' src={item.image} alt="" />
                                    <div className='absolute bottom-7 left-1/2 transform -translate-x-1/2 translate-y-1/2'>
                                        <Button className='bg-white text-black shadow' type="primary" onClick={() => showModal(item.image)}>View</Button>
                                    </div>
                                </div>
                                <div className='flex pl-2 justify-between flex-col gap-5'>
                                    <div className='flex items-center gap-4'>
                                        <small className='bg-red-50 font-medium text-red-500 px-2 rounded-[15px]'>Rejected</small>
                                        <small className='bg-violet-50 font-medium text-violet-500 px-2 rounded-[15px]'>Duplicate</small>
                                    </div>
                                    <div className='flex gap-7'>
                                        <div className='flex flex-col'>
                                            <small>DOB:</small>
                                            <small className='font-bold'>5/02/1993</small>
                                        </div>
                                        <div className='flex flex-col'>
                                            <small>ID NUMBER:</small>
                                            <small className='text-orange-500 font-bold'>57HJ768</small>
                                        </div>
                                    </div>
                                    <div className='flex items-center gap-1'>
                                        <small>Uploaded:</small>
                                        <small className='font-bold '>{item.time}</small>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    )}
                    </div>
                </section>
            </div>
            <Modal visible={isModalVisible} footer={null} onCancel={handleCancel} width={900} centered>
                <div className='flex flex-col gap-4'> 
                    <span className='text-xl font-bold'>Image</span>  
                    <div className='flex items-center gap-2'>
                        <small className='font-bold'>Status:</small>
                        <small className='bg-red-50 font-medium text-red-500 px-2 rounded-[15px]'>Rejected</small>
                        <small className='bg-violet-50 font-medium text-violet-500 px-2 rounded-[15px]'>Duplicate</small>
                    </div>
                    <img src={selectedImage} alt="Selected" className='object-cover' style={{ width: '100%',height:'400px' }} />
                </div>
            </Modal>
        </div>
    );
}
