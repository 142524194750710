import React from 'react';
import Propic from '../../Assets/images/blackAvatar.png';
import avat from '../../Assets/images/avatarfull.png';
import ID from '../../Assets/images/ID.png';
import Backview from '../../Assets/images/backview.png';
import Backviewdummy from '../../Assets/images/backviewdummy.jpg';
import { MdCircle } from "react-icons/md";

export default function FaceMatch() {

    const userInfo = {
        name: 'Tolani Daniel Olorunyomi',
        profilePic: Propic,
        credentials: [
            { id: '1', credential: ID, backview: Backview, type: 'National Identity Number' },
            { id: '2', credential: ID, backview: Backviewdummy, type: 'National Identity Number' },
            { id: '3', credential: ID, backview: Backview, type: 'National Identity Number' }
        ]
    };


    const Duplicates = [
        {
            type:'Identity Card',
            image:avat,
            quality:'High',
            time:'06/08/2024, 11:44am'
        },
        {
            type:'Selfie Shot',
            image:avat,
            quality:'High',
            time:'06/08/2024, 11:44am'
        }
    ];

    return (
        <div className='bg-gray-100'>
            <div className='px-5 py-3 border-b-[2px] border-b-gray-300 bg-white md:flex items-center justify-between gap-5'>
                <div className='md:flex items-center gap-3'>
                    <div className='flex items-center gap-5'>
                        <img className='rounded-full w-[50px] h-[50px]' src={userInfo.profilePic} alt="Profile" />
                        <div className='w-full font-bold'>{userInfo.name}</div>
                    </div>
                    <div className='flex md:justify-start justify-center items-center gap-10'>
                        <small className='flex items-center gap-2'><span className='text-green-700 font-bold'>Verified</span> <span className='font-bold text-gray-500'>5mins ago</span></small>
                        <small className='flex items-center gap-5'><span>Fraud Score <span className='text-violet-800 font-bold text-lg'>65%</span></span></small>
                    </div>
                </div>
                
                <div className='flex md:justify-start justify-center items-center gap-3'>
                    <span>Status:</span>
                    <small className='flex items-center gap-2 py-1 px-2 text-blue-600 bg-blue-100 rounded-[15px]'>
                        <MdCircle size={10} />
                        Active
                    </small>
                </div>
            </div>
            <div className='px-5 pb-5'>
                <section className='py-3'>
                    <div className='flex items-center gap-2'>
                        <span className='font-bold'>Face Match </span>
                        <span className='text-blue-600'>06/08/2024,02:17PM</span>
                    </div>
                    <div className='py-3 md:flex flex flex-col items-center flex-wrap gap-4'>
                    { Duplicates.map((item,index)=>
                        <div key={index} className='p-3 md:w-[35%] w-full bg-white rounded-md'>
                            <div className='flex flex-col'>
                            <small className={`mb-3 font-bold ${item.type === 'Identity Card' ? 'text-orange-500' : item.type === 'Selfie Shot' ? 'text-blue-800' : ''}`}>{item.type}</small>
                                <div>
                                    <img className='w-full h-[200px] object-cover' src={item.image} alt="" />
                                </div>
                                <div className='flex pl-2 justify-between mt-3 gap-2'>
                                    <div className='flex items-center gap-1'>
                                        <small>Uploaded:</small>
                                        <small className='font-bold '>{item.time}</small>
                                    </div>
                                    <div className='flex items-center gap-3'>
                                        <small>Quality:</small>
                                        <small className='bg-green-50 font-medium text-green-600 px-2 rounded-[15px]'>{item.quality}</small>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    )}
                    </div>
                </section>
            </div>
        </div>
    );
}
